.bg-receive-sms-usa{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
}
.usa-howto-receive{
    text-align:left;
}
.list-howto{
    list-style-type: square;
    color:light blue;
    font-size:25px;
    font-weight:bold;
}
.usa-num-table-card{
text-align:center;
}
.usa-num-head{
display:flex;
flex-direction:row;
justify-content:center;
align-items:center;
background-color:#937bad;
width:600px;
color:white;

}

.usa-num-margin{
    margin:10px;
    width:200px;
    font-size:15px;
}

.usa-num-head2{
    display:flex;
    flex-direction:row;
    justify-content:center;
    
    width:600px;
  }

  .usa-num-margin2{
    margin:10px;
    width:200px;
    font-size:15px;
}

@media screen and (max-width:768px) {
    .usa-num-head{
        width: 300px;
    }
}
@media screen and (max-width:768px) {
    .usa-num-head2{
        display:flex;
        flex-direction:row;
        justify-content:center;
        
        width:300px;
      }
}
@media screen and (max-width:768px) {
    .usa-num-margin{
        margin:10px;
        width:100px;
        font-size:15px;
    }
}
@media screen and (max-width:768px) {
    .usa-num-margin{
        margin:10px;
        width:100px;
        font-size:15px;
    }
}