.bg-receivesms-api {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.receivesms-head-card {
  text-align: center;
  margin-bottom: 10px;
}
.receivesms-sms-table-card {
  width: 600px;
}
.receivesms-body {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #937bad;
  color: white;
}
.receivedsms-item {
  margin: 15px;
  width: 150px;
}
.receivedsms-item2 {
  margin: 15px;
  width: 300px;
}
.receivesms-body-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.clipboardsms {
  background-color: rgb(19, 125, 202);
  color: white;
  height: 35px;
  width: 160px;
  border: none;
  border-radius: 5px;
  cursor: grab;
}
.re-sms-page {
  text-align: center;
  padding: 20px;
  margin: 10px;
}

.smsrefresh {
  background-color: rgb(19, 125, 211);
  color: white;
  height: 50px;
  width: 200px;
  border: none;
  border-radius: 5px;
  cursor: grab;
  font-size: 16px;
  padding: 10px;
}

@media screen and (max-width: 768px) {
  .receivesms-sms-table-card {
    width: 400px;
    font-size: 10px;
  }
}

@media screen and (max-width: 768px) {
  .receivesms-item {
    width: 50px;
  }
}
@media screen and (max-width: 768px) {
  .receivesms-item2 {
    width: 200px;
  }
}



.container {
    
  margin: 30px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
}

.dash {
  margin: 0 15px;

  width: 35px;

  height: 15px;

  border-radius: 8px;

  background: #ff6347;

  box-shadow: 0 0 10px 0 #ff7f50;
}

.uno {
  margin-right: -18px;

  transform-origin: center left;

  animation: spin 3s linear infinite;
}

.dos {
  transform-origin: center right;

  animation: spin2 3s linear infinite;

  animation-delay: 0.2s;
}

.tres {
  transform-origin: center right;

  animation: spin3 3s linear infinite;

  animation-delay: 0.3s;
}

.cuatro {
  transform-origin: center right;

  animation: spin4 3s linear infinite;

  animation-delay: 0.4s;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(360deg);
  }

  30% {
    transform: rotate(370deg);
  }

  35% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin2 {
  0% {
    transform: rotate(0deg);
  }

  20% {
    transform: rotate(0deg);
  }

  30% {
    transform: rotate(-180deg);
  }

  35% {
    transform: rotate(-190deg);
  }

  40% {
    transform: rotate(-180deg);
  }

  78% {
    transform: rotate(-180deg);
  }

  95% {
    transform: rotate(-360deg);
  }

  98% {
    transform: rotate(-370deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

@keyframes spin3 {
  0% {
    transform: rotate(0deg);
  }

  27% {
    transform: rotate(0deg);
  }

  40% {
    transform: rotate(180deg);
  }

  45% {
    transform: rotate(190deg);
  }

  50% {
    transform: rotate(180deg);
  }

  62% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  80% {
    transform: rotate(370deg);
  }

  85% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin4 {
  0% {
    transform: rotate(0deg);
  }

  38% {
    transform: rotate(0deg);
  }

  60% {
    transform: rotate(-360deg);
  }

  65% {
    transform: rotate(-370deg);
  }

  75% {
    transform: rotate(-360deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}
