


.nav-header {
    width: 100%;
    display: flex;
    background-color: #f8fafc;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
    font-family: 'Times New Roman', Times, serif;
  }
  .blog-title{
    font-size: 25px;
  }
  .nav-menu {
    display: flex;
    list-style: none;
    align-items: flex-start;
    
  }

  .nav-item-pp{
    
    font-size: 15px;
    text-align: center;
    
    
  }
  
  .nav-link {
    margin: 5px;
    color: black;
    text-decoration: none;
    
  }
 
 