.contact-cont{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    padding: 10px;
    
}
.bg-contact-name{
    margin: 20px;
    font-size: 20px;

}
.contact-head{
    text-align: center;
    font-size: 30px;
}
.contact-input{
    width: 200px;
    height: 35px;
    font-size: 15px;
    text-align: center;
    box-shadow: 5px shadow;
    border: 2px solid skyblue;
}
.contact-input-textarea{
    width: 440px;
    height: 100px;
    font-size: 25px;
    text-align: center;
    box-shadow: 5px shadow;
    border: 2px solid skyblue;
}

.bg-form-sub-cont{
    display: flex;
}
.error-field{
    color: green;
    font-size: 20px;
    text-align: center;

}

.button-contact{
    width: 80px;
    height: 40px;
    border: none;
    background-color: #1ead0e;
    color: white;
    margin: 20px;
    text-align: center;

}

@media screen and (max-width: 768px) {
    .contact-input-textarea{
        width: 340px;
        height: 100px;
        
    }
  }

  
@media screen and (max-width: 768px) {
    .contact-input{
        width: 150px;
        
    }
  }