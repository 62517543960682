.bg-foot{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #0f172a;
    color:white;
    margin-top: 10px;
    width: 100%;
}

.foot-link{
    color:white;
    text-decoration: none;
    margin: 5px;
}

.bg-cont-footer{
    display: flex;
}