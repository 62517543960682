.not-found-img {
    width: 100px;
  }
  
  .not-found-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }
  
  .button-style {
    color: white;
    background-color: orange;
    border: 0px;
    border-radius: 5px;
    width: 100px;
    height: 30px;
  }