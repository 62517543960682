.bg-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 100vw;
  max-width: 1110px; */
  width: 100%;
  margin: auto;
}

.bg-card-head-content {
  background-image: linear-gradient(to right, #031d47, #8d88b8, #937bad);
  background-image: cover;

  border-bottom-right-radius: 180px;
  border-top-left-radius: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 15px;
}
.main-head {
  text-align: center;
  font-size: 30px;
  font-weight: bolder;
  padding: 10px;
}
.sub-tag {
  text-align: left;
  padding: 30px;
  font-size: 15px;
}
.countries-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px;
}
.country-sub-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 400px;
  height: 100px;
  background-color: #937bad;
  color: white;
  padding: 15px;
}
.country-head {
  width: 200px;
  font-size: 25px;
  text-align: center;
}
.number-head {
  width: 200px;
  font-size: 25px;
  text-align: center;
}
.country-sub-para {
  padding: 30px;
  text-align: center;
}
.country-names-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 400px;
  padding: 15px;
}

.country-name-sub-head {
  width: 200px;
  font-size: 25px;
  text-align: center;
}

.numbers-name-sub-head {
  width: 200px;
  font-size: 25px;
  text-align: center;
}
.linkstyle {
  color: rgb(36, 135, 165);
  text-decoration: none;
}
.about-home {
  text-align: center;
  font-size: 24px;
}
.about-home-para {
  text-align: center;
  font-size: 18px;
  padding: 10px;
}

.about-service-home {
  text-align: center;
  padding: 25px;
}

.about-service-home-para {
  text-align: center;
  font-size: 18px;
  padding: 10px;
}
.about-card-home {
  background-color: white;
}

.temp-num-img {
  border-radius: 10px;
}

.feature {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.feature-para-format {
  text-align: left;
  font-size: 18px;
}

.flashit{
    color:rgb(156, 35, 180);
      -webkit-animation: flash linear 3s infinite;
      animation: flash linear 3s infinite;
  }
  @-webkit-keyframes flash {
      0% { opacity: 2; } 
      50% { opacity: .1; }
      100% { opacity: 3; }
  }
  @keyframes flash {
      0% { opacity: 2; } 
      50% { opacity: .1; }
      100% { opacity: 3; }
  }

.fe-sub-cont {
  width: 350px;
  padding: 10px;
}

@media screen and (min-width: 768px) {
  .about-home-card {
    padding: 25px;
  }
}
